<template>
  <Modal ref="modal">
    <h1 class="text-2xl font-serif">{{ $t('need_help_with_your_room_choices') }}</h1>

    <p class="my-4">{{ $t('my_preferences_for_a_room_are') }}:</p>

    <ul>
      <li class="my-1" v-for="filter in options?.data" :key="filter.id">
        <label :for="'checkbox-' + filter.id" class="inline-flex gap-2">
          <input :id="'checkbox-' + filter.id" type="checkbox" :value="filter.id" v-model="newSelected"
                 class="flex-none  border-qwr-dark-200 align-top appearance-none w-4 h-4 bg-[size:0.7rem] bg-no-repeat bg-center transition-colors border checked:bg-primary checked:border-primary checked:bg-checkbox">
          <div class="-mt-1">
            {{ filter.name }}
          </div>
        </label>
      </li>
    </ul>

    <template #footer>
      <div class="flex flex-col lg:flex-row gap-4 justify-between items-center">
        <Button variant="light" @click="$emit('filter', newSelected); closeModal()">
          {{ $t('filter') }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import {ref} from 'vue';
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';
import type {ProductFilterResource} from '@/api/resources';

export interface Props {
  group?: string,
  options: ProductFilterResource | null,
  selected?: number[] | null
}

const newSelected = ref<number[]>([]);
const props = defineProps<Props>();
const modal = ref<InstanceType<typeof Modal>>();

const openModal = () => {
  newSelected.value = props.selected ?? [];

  modal.value?.openModal();
};

const closeModal = () => {
  modal.value?.closeModal();
};

defineEmits<{
  (e: 'filter', selected: number[]): void
}>();

defineExpose({
  openModal,
  closeModal,
});
</script>
