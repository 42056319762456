import { z } from 'zod';

export const categoryResource = z.object({
  id: z.number(),
  parent_id: z.number(),
  name: z.string(),
});

export const uspResource = z.object({
  description: z.string().nullable(),
  icon: z.string().nullable(),
});

export const productResource = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  long_description: z.string().nullable().optional(),
  entity: z.string(),
  type: z.string(),
  price: z.string().nullable(),
  categories: z.array(categoryResource).optional(),
  image: z.string().nullable(),
  price_inclusive: z.boolean(),
  is_workshop: z.boolean()
});

export const upsellResource = z.object({
  product_id: z.number(),
  person_id: z.number(), // iteration per persons_count
});

export const stickerResource = z.object({
  visible: z.boolean(),
  text: z.string().nullable(),
});

export const stateResource = z.record(z.string().min(1), z.any());

export const personalDataResource = z.object({
  gender: z.string().nullable(),
  first_name: z.string().nullable(),
  infix: z.string().nullable(),
  last_name: z.string().nullable(),
  email: z.string().nullable(),
  phone_number: z.string().nullable(),
  birth_date: z.string().nullable(),
  address: z.object({
    street: z.string().nullable(),
    house_number: z.string().nullable(),
    house_number_addition: z.string().nullable(),
    city: z.string().nullable(),
    country: z.string().nullable(),
    zip_code: z.string().nullable(),
  }),
  opt_in: z.boolean(),
  thermen_fans: z.boolean(),
  send_invoice: z.boolean(),
  invoice: z.object({
    business_name: z.string().nullable(),
    first_name: z.string().nullable(),
    infix: z.string().nullable(),
    last_name: z.string().nullable(),
    email: z.string().nullable(),
    phone_number: z.string().nullable(),
    comment: z.string().nullable(),
    opt_in: z.boolean(),
    address: z.object({
      street: z.string().nullable(),
      house_number: z.string().nullable(),
      house_number_addition: z.string().nullable(),
      city: z.string().nullable(),
      country: z.string().nullable(),
      zip_code: z.string().nullable(),
    })
  })
});

export const timeslotResource = z.record(z.string(), z.boolean());

export const choiceResource = z.object({
  products: z.array(productResource),
  required: z.boolean()
});

export const receiptProductResource = z.object({
  icon: z.string().nullable(),
  item_id: z.number(),
  name: z.string(),
  slot: z.string(),
  price: z.string(),
  removable: z.boolean(),
});

export const receiptResource = z.object({
  reservation_costs: z.object({
    name: z.string(),
    icon: z.string(),
    price: z.string(),
  }).optional(),
  persons: z.array(z.object({
    person_id: z.number(),
    person_name: z.string(),
    items: z.array(receiptProductResource),
  })),
});

export const productSliderResource = z.object({
  data: z.object({ url: z.string() }),
  type: z.enum(['image', 'youtube_embed']),
});

export const productChoice = z.object({
  id: z.number(),
  name: z.string(),
  is_available: z.boolean(),
  price: z.string(),
  kind: z.enum(['PRODUCT']),
  slot: z.string(),
  selected: z.boolean(),
  description: z.string().nullable(),
  long_description: z.string().nullable().optional(),
  usps: uspResource.array(),
  slider: productSliderResource.array(),
  sticker: stickerResource.nullable().optional(),
  main_image_url: z.string().nullable(),
});

export const upsellProductResource = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  long_description: z.string().nullable().optional(),
  main_image_url: z.string().nullable(),
  duration: z.string().nullable().optional(),
  slider: productSliderResource.array(),
  is_available: z.boolean(),
  hide_when_not_available: z.boolean(),
  required_number_of_persons: z.number().optional(),
  available_rooms: z.number().optional(),
  price: z.string(),
  raw_price: z.number().optional(),
  kind: z.enum(['PRODUCT', 'BUNDLE']),
  slot: z.string(),
  product_set_ids: z.number().array().optional(),
  sticker: stickerResource.nullable().optional(),
  persons: z.object({
    id: z.number(),
    name: z.string(),
    selected: z.boolean(),
  }).array().optional(),
  choices: productChoice.array().optional(),
  usps: uspResource.array(),
});

export const hotelRoomCategoryResource = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  usps: uspResource.array(),
  image: z.string().nullable(),
  price: z.string().nullable().optional(),
  products: upsellProductResource.array()
});

// is something like categories..
export const upsellProductSetResource = z.object({
  id: z.number(),
  name: z.string(),
  children: z.object({
    id: z.number(),
    name: z.string(),
  }).array().optional(),
});

export const arrangementChoices = z.object({
  room_products: upsellProductResource.array().or(hotelRoomCategoryResource.array()),
  primary_products: upsellProductResource.array(),
  secondary_products: upsellProductResource.array(),
});

export const checkoutTypeResource = z.object({
  image: z.string().nullable(),
  name: z.string(),
  redirect_url: z.string(),
  type: z.string(),
});

export const checkoutResource = z.object({
  data: z.object({
    checkout_id: z.string(),
    product_id: z.number(),
    checkout_type: z.string(),
    arrival: z.object({
      date: z.string().nullable(),
      time: z.string().nullable(),
    }),
    departure: z.object({
      date: z.string().nullable(),
      time: z.string().nullable(),
    }),
    persons_count: z.number(),
    amount_of_rooms: z.number(),
    product_filters: z.array(z.number()).nullable().optional(),
    ga_linker_code: z.string().nullable(),
    state: stateResource,
  }),
  meta: z.object({
    product: productResource,
    countries: z.record(
      z.string(),
      z.string(),
    ),
    arrangement_choices: arrangementChoices.optional(),
    arrival_times: timeslotResource.optional(),
    departure_times: timeslotResource.optional(),
    exact_number_of_nights: z.number().nullable(),
    overwrite_fixed_price_for_exact_nights: z.string().nullable(),
    changes: z.string().array().optional(),
    total_price: z.string(),
    total_price_without_discount: z.string().nullable(),
    total_price_discount_reason: z.string().optional(),
    show_hotel_room_categories: z.boolean(),
    show_hotel_room_filters: z.boolean(),
    person_max: z.number(),
    person_min: z.number(),
    entree_product_ids: z.object({
      day: z.number().nullable(),
      morning: z.number().nullable(),
      evening: z.number().nullable(),
    }).optional(),
    receipt: receiptResource,
    supported_checkout_types: checkoutTypeResource.array(),
    upsells: z.record(
      z.string(),
      z.object({
        product_sets: upsellProductSetResource.array().optional(),
        products: upsellProductResource.array(),
      }),
    ),
    usps: uspResource.array(),
    voucher_codes: z.array(z.string()).nullable().optional(),
    bath_card_id: z.number().nullable().optional(),
    overwrite_comment_field: z.boolean(),
    overwritten_comment_text: z.string().nullable(),
    expand_comment_field: z.boolean(),
    links: z.object({
      house_rules: z.string(),
      terms_and_conditions: z.string(),
      cancellation_policy: z.string(),
      privacy_policy: z.string(),
    }).optional(),
    text: z.object({
      reservation: z.object({ title: z.string(), description: z.string() }),
      reservation_room_products: z.object({ title: z.string(), description: z.string() }),
      reservation_primary_products: z.object({ title: z.string(), description: z.string() }),
      customer: z.object({ title: z.string(), description: z.string() }),
      upsell_treatments: z.object({ title: z.string(), description: z.string() }),
      upsell_rituals: z.object({ title: z.string(), description: z.string() }),
      upsell_horeca: z.object({ title: z.string(), description: z.string() }),
      upsell_hotel: z.object({ title: z.string(), description: z.string() }),
    }),
  }),
  notices: z.record(
    z.string(),
    z.string().array(),
  ).optional(),
  calendar_notifications_above: z.string().array().optional(),
  calendar_notifications: z.string().array().optional(),
});

export const calendarResource = z.object({
  links: z.object({
    prev: z.string().nullable(),
    next: z.string().nullable(),
  }),
  meta: z.object({
    month: z.number(),
    year: z.number(),
  }),
  dates: z.record(
    z.string(),
    z.object({
      date: z.string(),
      available: z.boolean(),
      price: z.string(),
      status: z.string(),
      notifications: z.string().array(),
    }),
  ),
  availability_today: z.any(),
});

export const bookingResource = z.object({
  redirect_url: z.string(),
});

export const validationErrorResource = z.object({
  errors: z.record(z.string(), z.string().array()),
});

export const addressLookupResource = z.object({
  data: z.object({
    zipCode: z.string(),
    houseNumber: z.union([z.number(), z.string()]),
    street: z.string(),
    city: z.string(),
  }).nullable(),
});

export const productFilterResource = z.object({
  data: z.array(z.object({
    id: z.number(),
    name: z.string()
  })).nullable()
});

export const loginResource = z.object({
  authorization: z.object({
    access_token: z.string(),
    expires_in: z.number(),
    token_type: z.string(),
  }),
  member: z.object({
    gender: z.string().nullable(),
    first_name: z.string().nullable(),
    infix: z.string().nullable(),
    last_name: z.string().nullable(),
    email: z.string().nullable(),
    phone_number: z.string().nullable(),
    birth_date: z.string().nullable(),
    address: z.object({
      street: z.string().nullable(),
      house_number: z.string().nullable(),
      house_number_addition: z.string().nullable(),
      city: z.string().nullable(),
      country: z.string().nullable(),
      zip_code: z.string().nullable(),
    }).nullable(),
  }),
});

export const meResource = z.object({
  member: z.object({
    gender: z.string().nullable(),
    first_name: z.string().nullable(),
    infix: z.string().nullable(),
    last_name: z.string().nullable(),
    email: z.string().nullable(),
    phone_number: z.string().nullable(),
    birth_date: z.string().nullable(),
    address: z.object({
      street: z.string().nullable(),
      house_number: z.string().nullable(),
      house_number_addition: z.string().nullable(),
      city: z.string().nullable(),
      country: z.string().nullable(),
      zip_code: z.string().nullable(),
    }).nullable(),
  }),
});

export const forgotPasswordResource = z.object({
  status: z.string(),
});

// Laravel resource wraps data into a data object.
export type ResourceData<T> = { data: T }
export type CategoryResource = z.infer<typeof categoryResource>;
export type CheckoutTypeResource = z.infer<typeof checkoutTypeResource>;
export type ProductResource = z.infer<typeof productResource>;
export type StateResource = z.infer<typeof stateResource>;
export type PersonalDataResource = z.infer<typeof personalDataResource>;
export type CheckoutResource = z.infer<typeof checkoutResource>;
export type ReceiptResource = z.infer<typeof receiptResource>;
export type CalendarResource = z.infer<typeof calendarResource>;
export type BookingResource = z.infer<typeof bookingResource>;
export type UpsellProductResource = z.infer<typeof upsellProductResource>;
export type HotelRoomCategoryResource = z.infer<typeof hotelRoomCategoryResource>;
export type UpsellProductSetResource = z.infer<typeof upsellProductSetResource>;
export type ValidationErrorResource = z.infer<typeof validationErrorResource>;
export type ReceiptProductResource = z.infer<typeof receiptProductResource>;
export type ProductSliderResource = z.infer<typeof productSliderResource>;
export type UspResource = z.infer<typeof uspResource>;
export type AddressLookupResource = z.infer<typeof addressLookupResource>;
export type ProductFilterResource = z.infer<typeof productFilterResource>;
export type LoginResource = z.infer<typeof loginResource>;
export type MeResource = z.infer<typeof meResource>;
export type ProductChoice = z.infer<typeof productChoice>;
export type ForgotPasswordResource = z.infer<typeof forgotPasswordResource>;
