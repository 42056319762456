<template>
  <div class="flex flex-col md:flex-row gap-4 md:items-center">
    <Button variant="light" class="relative w-full md:w-auto" @click="modal?.openModal()">
      {{ $t('need_help_with_your_room_choices') }}

      <div class="absolute right-2 -top-3" v-if="amount_of_selected_filters > 0">
          <div class="rounded-full bg-[#b79f56] text-white w-6 h-6 pl-[1px] flex font-bold justify-center items-center text-xs">
            {{ amount_of_selected_filters }}
          </div>
      </div>
    </Button>

    <div class="cursor-pointer text-qwr-haze-600 underline" v-if="amount_of_selected_filters > 0" @click="setProductFilters([])">{{ $t('reset_filters') }}</div>

    <ProductFilterModal :selected="checkout.data.product_filters" ref="modal" :options="options" @filter="handleFiltering" />
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref, onMounted } from 'vue';
  import api from '@/api';
  import type {ProductFilterResource} from "@/api/resources";
  import Button from "@/components/Button.vue";
  import ProductFilterModal from "@/components/modals/ProductFilterModal.vue";
  import {useFlowStore} from "@/stores/useFlowStore";

  export interface Props {
    slot: string
  }

  const { checkout, setProductFilters } = useFlowStore();
  const modal = ref<InstanceType<typeof ProductFilterModal>>()
  const options = ref<ProductFilterResource>({} as ProductFilterResource)
  const props = defineProps<Props>();
  const amount_of_selected_filters = computed(() => {
    return (options.value?.data ?? [])
      .filter(filter => checkout.data.product_filters?.includes(filter.id))
      .length
  })

  onMounted(() => {
    fetchOptions();
  })

  const fetchOptions = async () => {
    const data = await api.checkout.getProductFilters(props.slot)
    if (data) options.value = data;
  }

  const handleFiltering = (selected_options: number[]) => {
    // Only when we have any options
    if(options.value && options.value.data) {
      // Get current set of available options in the modal
      const option_ids = options.value.data.map(option => option.key);

      // Get the current filters
      const new_filters = (checkout.data.product_filters ?? [])
        // Filter every current available option out of the selected filters
        .filter(filter => !option_ids.includes(filter.id))
        .map(filter => filter.id)
        // Re-apply every newly selected option
        .concat(selected_options)

      // Update the product filters
      setProductFilters(new_filters)
    }
  }

</script>
